.header-area {
  top: 0 !important;
  padding-top: env(safe-area-inset-top) !important
}
* {
  font-family: 'Roboto', sans-serif !important;
}

.ba-navbar-show {
  z-index: 9999 !important;
}
.bg-dark-blue {
  background-color: #2b2baf !important;
}

.section-login {
  background-size: cover;
  margin-top: -60px !important;
}

.section-login button.go-back {
  color: #fff;
  background-color: transparent;
  border: none;
  font-size: 40px;
  font-weight: 200;
  padding: 0;
}
.section-login .title h4 {
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.section-login input {
  font-size: 16px;
  background-color: transparent;
  border: none;
  border-bottom: solid 1px #fff;
  color: #fff;
  padding: 0;
}
.section-login form.register input {
  height: 65px;
  padding: 22px 28px 22px 28px;
  border-radius: 10px;
  background-color: rgba(247, 247, 247, 0.5);
  border: none !important;
}
.section-login input:active,
.section-login input:focus {
  border: none;
  border-bottom: solid 1px #fff;
}
.section-login input::placeholder {
  font-size: 16px;
  color: #fff;
}
.section-login .text-right span {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.section-login .btn-login {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  width: 75%;
  height: 80px;
  border-radius: 10px;
  padding: 26px 0 28px;
  background-color: #ff4d45;
  text-align: center;
}
.section-login .social-login span {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.section-login .social-login img {
  width: 45px;
  height: 45px;
}
.section-login .bottom {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.section-login .bottom {
  padding: 70px 0 70px;
  text-align: center;
}
.section-login .bottom-register {
  padding: 20px 0 20px;
  text-align: center;
}
.section-login .bottom a {
  font-weight: bold;
}
.highlight {
  color: red;
}

/*----------------------------------------------
    # react-calendar
----------------------------------------------*/
.react-calendar {
  width: 100%;
  max-width: 100%;
  /* background-color: #fff; */
  color: #222;
  /* border-radius: 8px; */
  display: block;
  height: auto;
  border: 0;
  /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar__navigation button {
  color: #2b2baf;
  min-width: 24px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
  text-transform: capitalize;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
  color: #868686 !important;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #2b2baf;
  border-radius: 6px;
  color: #fff;
}
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

abbr[title] {
  text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */
.react-calendar__tile {
  padding: 10px 3px;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #2b2baf;
  border-radius: 6px;
}
/* .react-calendar__tile--now {
  background: #2b2baf;
  border-radius: 6px;
  font-weight: bold;
  color: white;
} */
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #2b2baf;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
.react-calendar__tile--active {
    background: #2b2baf !important;
    color: white !important;
  border-radius: 6px !important;
  font-weight: bold;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2b2baf;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
.react-calendar__tile--range {
  background: #f8f8fa;
  color: #2b2baf;
  border-radius: 0;
}
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #2b2baf;
  color: white;
}
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #2b2baf;
  color: white;
}
